<template>
	<div>
		<el-drawer :wrapperClosable="false" :title="'价格配置更新'" append-to-body :visible.sync="dialogFile" :direction="'rtl'"
			size="800px">
			<div style="padding: 10px;">
				<el-form ref="form" inline :rules="formRules" :model="form" label-width="150px" v-loading="loading">

					<!-- <el-form-item :label="$t('c944a6686d996ab3')" prop="">
						<whNoSelect ref="whNoSelect" :size="''" @changeData="changWhNo"></whNoSelect>
					</el-form-item>
					
					<el-form-item :label="$t('hytxs0000060')" prop="">
						<cusSelFuzzy ref="cusSelFuzzy2" :size="''" @changeData="changCus2"></cusSelFuzzy>
					</el-form-item> -->

					<el-form-item :label="'费用类型'" prop="">
						<el-input type="text" clearable v-model="form.feeType"></el-input>
					</el-form-item>

					<el-form-item :label="'zone2Price'" prop="">
						<el-input-number v-model="form.zone2Price">
						</el-input-number>
					</el-form-item>

					<el-form-item :label="'zone3Price'" prop="">
						<el-input-number v-model="form.zone3Price">
						</el-input-number>
					</el-form-item>
					<el-form-item :label="'zone4Price'" prop="">
						<el-input-number v-model="form.zone4Price">
						</el-input-number>
					</el-form-item>
					<el-form-item :label="'zone5Price'" prop="">
						<el-input-number v-model="form.zone5Price">
						</el-input-number>
					</el-form-item>
					<el-form-item :label="'zone6Price'" prop="">
						<el-input-number v-model="form.zone6Price">
						</el-input-number>
					</el-form-item>
					<el-form-item :label="'zone3Price'" prop="">
						<el-input-number v-model="form.zone3Price">
						</el-input-number>
					</el-form-item>
					<el-form-item :label="'zone7Price'" prop="">
						<el-input-number v-model="form.zone7Price">
						</el-input-number>
					</el-form-item>
					<el-form-item :label="'zone8Price'" prop="">
						<el-input-number v-model="form.zone8Price">
						</el-input-number>
					</el-form-item>
					<el-form-item :label="'zone9Price'" prop="">
						<el-input-number v-model="form.zone9Price">
						</el-input-number>
					</el-form-item>
					<el-form-item :label="'zone10Price'" prop="">
						<el-input-number v-model="form.zone10Price">
						</el-input-number>
					</el-form-item>
					<el-form-item :label="'zone11Price'" prop="">
						<el-input-number v-model="form.zone11Price">
						</el-input-number>
					</el-form-item>
					<el-form-item :label="'zone12Price'" prop="">
						<el-input-number v-model="form.zone12Price">
						</el-input-number>
					</el-form-item>

					<el-form-item :label="'是否支持'" prop="">
						<!-- <el-input type="text" clearable v-model="form.isSupport"></el-input> -->
						<el-select v-model="form.isSupport" placeholder="请选择">
							<el-option :label="'是'" :value="'1'">
							</el-option>
							<el-option :label="'否'" :value="'0'">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item :label="'是否收取服务费'" prop="">
						<!-- <el-input type="text" clearable v-model="form.isAddSf"></el-input> -->
						<el-select v-model="form.isAddSf" placeholder="请选择">
							<el-option :label="'是'" :value="'1'">
							</el-option>
							<el-option :label="'否'" :value="'0'">
							</el-option>
						</el-select>
					</el-form-item>

					<el-form-item :label="'对账属性'" prop="">
						<el-input type="text" clearable v-model="form.billAtrr"></el-input>
					</el-form-item>

					<el-form-item :label="'备注'" prop="">
						<el-input type="textarea" clearable v-model="form.remark"></el-input>
					</el-form-item>

					<!-- <el-form-item :label="'remoteLevel'" prop="">
						<el-input type="text" clearable v-model="form.remoteLevel"></el-input>
					</el-form-item>

					<el-form-item :label="'zipCode'" prop="">
						<el-input type="text" clearable v-model="form.zipCode"></el-input>
					</el-form-item> -->
					<!-- <el-form-item :label="'clientKey'" prop="">
						<el-input type="text" clearable v-model="form.clientKey"></el-input>
					</el-form-item>
					<el-form-item :label="'clientSecret'" prop="">
						<el-input type="text" clearable v-model="form.clientSecret"></el-input>
					</el-form-item> -->


					<!-- <el-form-item :label="$t('Storage.tableColumn.remark')">
						<el-input type="textarea" :placeholder="$t('FormMsg.Please_Enter')" v-model="form.remark"  :maxlength="1000" show-word-limit></el-input>
					</el-form-item> -->

				</el-form>
			</div>

			<div class="drawer-footer">
				<el-button type="primary" icon="el-icon-tickets"
					@click="submitForm('form')">{{ $t('FormMsg.Save') }}</el-button>
				<!-- <el-button type="primary" plain @click="dialogFile = false">{{$t('4e9fc68608c60999')}}</el-button> -->
			</div>
		</el-drawer>

	</div>
</template>
<script>
	import {
		deepClone,
		clearObjectVal
	} from '@/utils/utils.js';

	import {
		getDicData
	} from '@/axios/common.js';

	// import cusSelFuzzy from '@/components/WarehouseCenter2/components/cusSelFuzzy.vue';
	// import whNoSelect from '@/components/WarehouseCenter2/components/whNoSelect.vue';

	export default {

		props: {
			openTime: {
				// default: function() {
				//   return '';
				// },
				// type: String
			},
			row: {}
		},
		components: {
			// cusSelFuzzy,
			// whNoSelect
		},
		data() {
			return {
				dialogFile: false,

				loading: false,
				// form: {
				// 	"userId":"",
				// 	"thridCode":"",
				// 	"whNo":"",
				// 	"thridWhNo":"",
				// 	"apiUrl":"",
				// 	"clientKey":"",
				// 	"clientSecret":""
				// },
				form: {
					// "country":"",
					// "remoteLevel":"",
					// "zipCode":"",

					"id": "",
					"feeType": "",
					"zone2Price": "",
					"zone3Price": "",
					"zone4Price": "",
					"zone5Price": "",
					"zone6Price": "",
					"zone7Price": "",
					"zone8Price": "",
					"zone9Price": "",
					"zone10Price": "",
					"zone11Price": "",
					"zone12Price": "",
					"isSupport": "", //"是否支持",
					"isAddSf": "" //"是否加服务费",

				},

				formRules: {

				},

				selectOption: {

				},

			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				console.log('openTime');
				this.dialogFile = true;
				this.initData();
			}
		},
		//创建时
		created() {

		},
		//编译挂载前
		mounted() {

		},
		methods: {
			initData() {
				// // 重置
				this.resetForm('form');
				this.form = Object.assign({}, this.row);
				// if (!!this.row) {
				// 	this.getDetData(this.row.id);
				// }
			},

			changCus2(data) {
				console.log('changCus', data);
				this.form.userId = data.userId;
			},
			changWhNo(data) {
				console.log('changWhNo', data);
				this.form.whNo = data.code;
			},

			// getDetData(keyId) {
			// 	this.loading = true;
			// 	this.$http
			// 		.get(this.$urlConfig.WhThirdWmsConfigQuery + "/" + keyId, {})
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log(data);
			// 			this.loading = false;
			// 			if (200 == data.code) {
			// 				this.form = data.data;
			// 				this.$nextTick(() => {
			// 					this.$refs.cusSelFuzzy2.init(this.form.userId);
			// 					this.$refs.whNoSelect.init(this.form.whNo);
			// 				});
			// 			} else {
			// 				this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
			// 					type: 'warning'
			// 				});
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			this.loading = false;
			// 		});
			// },

			//提交信息
			submitForm(formName) {
				this.$refs[formName].validate(valid => {
					if (valid) {
						let formData = Object.assign({}, this.form);
						// if(!!formData.id){
						// 	this.postData(this.$urlConfig.WhRemoteAreaCodeConfigAdd, formData, '', () => {
						// 		this.dialogFile = false;
						// 		this.$emit('success');
						// 	});
						// } else {
						this.postData(this.$urlConfig.WhCarrFeePriceConfigUpdateList, [formData], '', () => {
							this.dialogFile = false;
							this.$emit('success');
						});
						// }

					} else {
						console.log('error submit!!');
						this.$alert(this.$t('tips.checkSubmitData'), this.$t('tips.tipsTitle'), {
							type: 'warning'
						});
						return false;
					}
				});
			},
			//重置输入框
			resetForm(formName) {
				console.log(formName);
				if (this.$refs[formName]) {
					this.$refs[formName].resetFields();
					this.form = clearObjectVal(this.form);
				} else {
					console.log('this.$refs[formName]', this.$refs[formName]);
				}

				// this.$nextTick(() => {
				// 	this.$refs.cusSelFuzzy2.init('');
				// 	this.$refs.whNoSelect.init('');
				// });
			},

			//提交信息
			postData(url, formData, type, callback) {
				this.loading = true;
				let HttpType = {};
				if ('delete' == type) {
					HttpType = this.$http.delete(url, formData);
				} else {
					HttpType = this.$http.put(url, formData);
				}
				HttpType.then(({
					data
				}) => {
					this.loading = false;
					if (200 == data.code) {
						callback();
					} else {
						this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
							type: 'warning'
						});
					}
				}).catch(error => {
					console.log(error);
					console.log(this.$t('tips.submitError'));
					this.loading = false;
					this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
						type: 'warning'
					});
				});
			},

		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>